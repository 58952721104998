@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap');
@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;


*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Mulish";
  /* font-size: 16px; */
}

@layer components {

  .landing-page .header-logos {
    @apply flex justify-between items-center;
  }

  .removing {
    opacity: 0.3;
    transition: opacity 5s;
  }
  /* SideBar */
  .sidebar {
    min-width: 345px;
    @apply flex flex-col gap-y-4 h-screen bg-dark-blue text-white p-2 pt-4 relative duration-300;
    padding: 2rem 0;
  }

  .sidebar_container {
    display: grid
  }

  .sidebar.minimized{
    min-width: 60px;
    max-width: 60px;
  }

  .sidebar__app-bar {
    @apply flex gap-2;
    margin-left: 1.5rem;
    flex-direction: column;
  }

  .minimized .sidebar__app-bar {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }

  .sidebar__app-title {
    @apply text-white font-medium text-xl duration-300;
    line-height: 0.75rem;
  }

  .sidebar__app-title .logo-title {
    margin-top: -0.5rem;
    margin-left: 90px;
    font-size: 1.5rem;
  }

  .sidebar__app-title .logo-subtitle {
    font-size: 1rem;
  }

  .sidebar__btn-close {
    @apply cursor-pointer duration-300 text-white justify-end;
  }

  .sidebar__new-chat {
    font-size: 1.5rem;
    margin: 2.5rem 1rem 1rem 1rem;
  }

  .minimized .sidebar__new-chat {
    margin-left: 0.4rem;
  }

  .sidebar__btn-icon {
    @apply w-8 h-8;
  }

  .sidebar__app-logo {
    @apply cursor-pointer duration-300;
    display: flex;
    justify-content: space-between;
  }

  .sidebar__app-logo .skechai-logo {
    width: 275px;
    margin-top: -5px;
    margin-right: 1rem;
    margin-left: .75rem;
  }

  .minimized .sidebar__app-logo .skx-logo {
    width: 48px;
  }

  .minimized .sidebar__app-logo .skechai-logo {
    display: none;
  }

  .sidebar-sub {
    @apply bg-blue-700 fixed top-0 left-0 h-full w-1/2 z-50 transition-transform duration-300 ease-in-out;
  }


  /* SideBar Nav */
  .nav {
    @apply flex ;
  }

  .nav__item {
    @apply px-4 py-3 flex items-center gap-x-4 w-screen
    rounded-md cursor-pointer
    hover:bg-light-white
    text-white text-base;
  }

  .nav__icons {
    @apply cursor-pointer duration-300 text-white
    text-xl;
  }

  .nav__bottom {
    @apply flex flex-col justify-end fixed bottom-8;
  }

  .nav__msg {
    @apply flex bg-yellow-900 rounded-xl p-4 text-white;
  }

  .nav__p {
    @apply font-mono;
  }

  .nav-icon {
    width: 23px;
    height: 24px;
    object-fit: contain;
  }

  /* Disclaimer Modal */
  .disclaimer-modal .modal-box {
    max-width: 50rem;
  }

  .use-case-params-modal .modal-box {
    max-width: 64rem;
    max-height: 60rem;
  }

  /* MOBILE */
  .mobile__header {
    height: relative;
    background-image: url('/src/assets/landing_header.png');
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .dropdown__container {
    background-color: transparent;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .dropdown__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    max-width: none;
    white-space: nowrap;
    background-color: transparent;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    cursor: pointer;
    z-index: 20;
    border: 1px solid #0063BA;
    margin: 0 auto;
    font-family: 'Mulish';
    font-size: 16px;
    font-weight: 700;
    line-height: 20.08px;
    letter-spacing: 0.02em;
    text-align: center;
  }

  .dropdown__button img {
    width: 8px;
    height: 6px;
  }

  .model-dropdown {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 400px;
    z-index: 30;
  }

  .model-type-dropdown {
    background-color: #103B62;;
    color: white;
    border-radius: 1rem;
    padding: 1rem;
    width: 100%;
    max-width: 400px;
    position: relative;
  }

  .model-type-option {
    display: flex;
    align-items: center;
    padding: 1rem;
    margin-bottom: 0.5rem;
    text-decoration: none;
    color: white;
    position: relative;
    z-index: 1;
  }

  .model-type-icon {
    width: 51px;
    height: 51px;
    background-color: #1D4A72;
    ;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    flex-shrink: 0;
  }

  .model-type-icon img {
    width: 24px;
    height: 25px;
    object-fit: contain;
  }

  .model-type-content {
    font-family: 'Mulish';
    font-size: 12px;
    font-weight: 400;
    line-height: 15.06px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 1.5rem;
  }

  .model-type-option h3 {
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 700;
    color: #4CF6FF;
    line-height: 20.08px;
    font-family: 'Mulish';
    letter-spacing: 2%;
  }

  .check-icon {
    position: absolute;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
    width: 21px;
    height: 21px;
  }

  .dropdown-separator {
    position: absolute;
    left: 5%;
    right: 5%;
    top: 50%;
    height: 1px;
    background-color: #0063BA;
    transform: translateY(-50%);
  }

  .chat-history-title {
    width: auto;
    height: 17px;
    margin-bottom: 40px;
    font-family: 'Mulish', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 30.12px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #000000;
  }

  .chat-history-header {
    margin-bottom: 24px;
    padding-top: 16px;
  }

  .chat-history-item {
    margin-bottom: 20px;
    border: 1px solid #E5E7EB;
    position: relative;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    min-height: 120px;
  }

  .chat-history-item h2 {
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.08px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000000;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .chat-history-item .description {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.57px;
    text-align: left;
    margin-bottom: 4px;
    color: #6B7280;
  }

  .chat-history-item .date {
    font-family: 'Mulish';
    font-size: 12px;
    font-weight: 700;
    line-height: 15.06px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #434343;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: auto;
    padding-top: 8px;
  }

  .chat-history-item button {
    padding: 4px;
    position: absolute;
    bottom: 16px;
    right: 16px;
  }

  .sidebar__app-logo-mobile .skechai-logo {
    width: 230px;
    margin-top: -5px;
    margin-right: 1rem;
    margin-left: .75rem;
  }

 /* Saved Prompts */

  .saved-prompts-container {
    display: flex;
    transition: 500ms ease-in-out;
  }

  .saved-prompts-main {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-color: white;
  }

  .saved-prompts-content {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
  }

  .saved-prompts-loading,
  .saved-prompts-empty {
    text-align: center;
    color: #6B7280;
    margin-top: 1rem;
  }

  .saved-prompt-title {
    width: auto;
    height: 17px;
    margin-bottom: 40px;
    font-family: 'Mulish', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 30.12px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #000000;
  }

  .saved-prompt-header {
    margin-bottom: 24px;
    padding-top: 16px;
  }

  .saved-prompt-item {
    margin-bottom: 20px;
    border: 1px solid #E5E7EB;
    position: relative;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    min-height: 120px;
    background-color: white;
    transition: all 500ms;
  }

  .saved-prompt-item.deleting {
    background-color: #FEE2E2;
    opacity: 0.5;
  }

  .saved-prompt-content {
    flex-grow: 1;
    overflow: hidden;
  }

  .saved-prompt-name {
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.08px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000000;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .saved-prompt-description {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.57px;
    text-align: left;
    margin-bottom: 4px;
    color: #6B7280;
  }

  .saved-prompt-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .saved-prompt-date {
    font-family: 'Mulish';
    font-size: 12px;
    font-weight: 700;
    line-height: 15.06px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #434343;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: auto;
    padding-top: 8px;
  }

  .saved-prompt-actions {
    display: flex;
    align-items: center;
  }

  .saved-prompt-action-button {
    padding: 4px;
    position: absolute;
    bottom: 16px;
    right: 16px;
  }

  .saved-prompt-action-icon {
    width: 16px;
    height: 16px;
  }

  /* Confirmation Modal */

  .confirmation-modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }

  .confirmation-modal-content {
    background-color: #103B62;
    color: white;
    width: 100%;
    height: 199px;
    padding: 24px;
    border-radius: 10px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1rem;
  }

  .confirmation-modal-text {
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.08px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 36px;
  }

  .confirmation-modal-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .confirmation-modal-button {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.57px;
    border-radius: 5px;
    text-align: center;
    padding: 0.5rem 1rem;
  }

  .confirmation-modal-button-cancel {
    border: 1px solid white;
    background-color: transparent;
    color: white;
  }

  .confirmation-modal-button-delete {
    background-color: #0063BA;
    color: #FFFFFF;
  }

  /* Landing Page  */

  .landing-container {
    @apply flex flex-col min-h-screen landing-page;
  }

  .landing-header-container {
    @apply sticky z-50 top-0 p-4 flex flex-col items-center bg-hero-pattern text-white;
  }

  .text-image-container {
    @apply flex flex-col justify-center flex-grow bg-gray-200;
  }

  .landing-button {
    @apply m-6 drop-shadow-xl w-full max-w-[533px];
  }

  .landing-button-sub {
    @apply bg-white flex flex-col px-10 h-auto md:h-64 w-full rounded-b-[2rem];
  }

  .landing-font {
    @apply text-2xl py-4 font-bold text-center;
  }

  .landing-sub-button {
    @apply h-16 py-6 text-center flex flex-col justify-end;
  }

  .launch-button {
    @apply btn rounded-md text-lg capitalize h-8 min-h-fit px-12 py-0;
  }

  .learn-more {
    @apply btn rounded-md bg-gray-600 text-xl capitalize px-12 mb-2;
  }

  .landing-footer {
    @apply flex flex-col md:flex-row justify-center items-center bottom-0 p-4 bg-footer-texture h-auto md:h-20;

  }

  .landing-sub-footer {
    @apply text-white text-lg text-center md:text-left mb-2 md:mb-0;
  }

  .landing-skechers-logo {
    @apply w-24 mt-2 md:mt-0 md:absolute md:right-4;
  }

  .chat-history-sub {
    @apply bg-white rounded-lg p-4 mb-4 shadow-sm transition-all duration-500
  }

  /* Mobile Config Menu Header */

  .config-header {
    @apply flex transition duration-500 ease-in-out

  }
  .config-settings {
    @apply flex justify-between items-center px-4 py-2 bg-gray-200 pt-2
  }

  .config-settings-padding {
    @apply pt-2 pb-1 pr-1 bg-gray-200
  }

  .config-divider {
    @apply h-px bg-gray-400 w-11/12 mx-auto
  }

  /* Mobile Save Prompts */

  .save-area-mobile {
    @apply text-black rounded-lg p-2 resize-none box-border;
  }

  .save-area-mobile-error {
    @apply border-[0.5px] border-2 border-red-500 box-border;
  }

  .save-area-mobile-no-error {
    @apply border-[0.5px] border-transparent;
  }

  .save-prompt-button-general {
    @apply text-white rounded-lg px-4 sm:px-5 py-2 text-sm sm:text-base capitalize
  }

  .save-prompt-button-align {
    @apply flex items-center justify-center space-x-2
  }

  .save-prompt-bottom-padding {
    @apply flex flex-col mb-4;

  }

  .save-area-content {
    @apply text-black rounded-lg p-2 resize-none flex-grow
  }

  .general-save-prompt {
    @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[1000] w-11/12 h-[65vh] rounded-3xl flex flex-col justify-between;
  }

  .general-sub-save-prompt {
    @apply bg-dark-blue w-full h-full rounded-3xl flex flex-col justify-between;
  }

  .save-prompt-exit-align {
    @apply flex flex-row-reverse items-center justify-between px-4 sm:px-6 py-4;
  }

  .save-prompt-general-content {
    @apply flex flex-col px-4 sm:px-6 flex-grow overflow-y-auto;
  }

  /* CHATVIEW */
  .chatcontainer {
    @apply flex flex-col h-screen duration-300 overflow-hidden relative dark:bg-light-grey;
  }

  .chatview {
    overflow: auto;
    flex-grow: 1;
  }

  .chatview__header .skechai-logo {
    margin: -0.5rem auto;
    max-height: 3rem;
  }

  .chatview__chatarea {
    @apply w-full overflow-y-scroll;
  }

  .chatview__chatarea .message_wrapper {
    min-width: 700px;
  }

  .avatar > div {
    @apply bg-light-blue;
    width: 4rem;
    padding: 1rem;
  }
  .form {
    @apply flex justify-between flex-col items-stretch sm:flex-row m-2 mb-0 bottom-0 left-0 right-0
    text-black dark:text-white duration-300;
  }

  .form.chat .file-upload .btn {
    width: 70px;
  }

  .dropdown {
    @apply dark:bg-light-grey h-16 px-3 rounded-xl bg-light-white
     hover:bg-slate-200 hover:dark:bg-dark-grey border-2 border-slate-400
    dark:border-slate-500 focus:ring-offset-indigo-200 text-black
     dark:text-white transition  ease-in-out duration-300 text-center
     text-base font-semibold shadow-md  focus:outline-none focus:ring-2
      focus:ring-offset-2;
  }

  .message__img {
    @apply rounded-lg shadow-lg bg-cover bg-gray-400 w-[512px] duration-300 ease-out;
  }

  .chatview__textarea-message {
    @apply bg-light-white ml-0 sm:ml-2 dark:bg-light-grey text-xl h-16 sm:w-full grow max-h-[20rem] min-h-[4rem] p-2
    border-2 border-slate-400 dark:border-slate-500 overflow-y-hidden mx-2
    focus:ring-offset-indigo-200 focus:ring-2 focus:ring-offset-2
    resize-y rounded-xl;
  }

  .chatview__btn-send {
    @apply h-16 w-16 px-4 rounded-full hover:bg-slate-200 hover:dark:bg-dark-grey border-2 border-slate-400
    dark:border-slate-500 focus:ring-offset-indigo-200 text-black dark:text-white transition
    ease-in-out duration-300 text-center text-base font-semibold shadow-md
    focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .chatview__btn-send:disabled {
    @apply opacity-40 transition ease-in duration-300 text-center text-base font-semibold shadow-md;
  }


  /* ChatMessage */
  .message {
    @apply py-5 gap-2 flex items-start justify-end;
    padding: 1.5rem;
  }
  /*
  .message__markdown * {
    all: revert;
  } */

  .message__wrapper {
    @apply flex-grow w-screen flex-wrap overflow-hidden;
    padding: 0 1.5rem;
  }

  .message__markdown {
    @apply text-xl text-slate-700 dark:text-slate-200;
  }

  .message__createdAt {
    @apply text-sm font-thin italic text-slate-600 dark:text-slate-300;
  }

  .message__pic {
    @apply h-8 w-8 mx-2 rounded-full flex items-center justify-center text-xl;
  }

  .message__thinking {
    @apply animate-pulse duration-1000 h-12 w-12 dark:text-white text-black text-xl;
  }

  .image__group__wrapper {
    @apply flex;
  }

  .image__wrapper {
    @apply p-1;
  }

   /* Desktop use-case styles */

  .use-case-library-header {
    @apply container top-0 bg-white shadow-md text-center text-2xl font-bold py-4;
  }

  .use-case-library-welcome {
    @apply ml-16 mr-20 mt-12;
  }

  .use-case-library-text {
    @apply text-xl leading-9;
  }

  .use-case-grid {
    @apply flex ml-16 mr-20 mt-6 flex-wrap;
  }

  .use-case-card {
    @apply flex flex-col m-3;
  }

  .use-case-image-container {
    @apply w-full h-1/2 rounded-b-3xl flex shrink-0;
  }

  .use-case-title-container {
    @apply w-full h-1/2 bg-sky-100 relative overflow-hidden rounded-b-3xl flex shrink-0;
  }

  .use-case-title {
    @apply ml-6 p-3 text-base font-bold text-left text-blue-900;
  }

  /* Mobile use-case styles */

  .mobile-use-case-header {
    @apply text-center mb-6 px-8;
  }

  .mobile-use-case-title {
    @apply text-2xl font-bold leading-[30.12px] tracking-[0.02em] text-center mb-2;
  }

  .mobile-use-case-subtitle {
    @apply text-lg font-bold leading-[22.59px] text-center mb-2;
  }

  .mobile-use-case-description {
    @apply text-base font-normal leading-[20.08px] tracking-[0.02em] text-center mb-2;
  }

  .mobile-use-case-grid {
    @apply grid grid-cols-2 gap-4 px-4;
  }

  .mobile-use-case-card {
    @apply bg-white rounded-lg shadow-md overflow-hidden;
  }

  .mobile-use-case-image {
    @apply w-full h-24 object-cover;
  }

  .mobile-use-case-title-container {
    @apply p-3 bg-sky-100;
  }

  .mobile-use-case-card-title {
    @apply text-sm font-bold text-blue-900;
  }

  /* Product Knowledge Base Use Case */

  .pk-header {
    @apply flex bg-white !shadow-lg shadow-sky-600 justify-between items-center
    relative z-20
  }

  .pk-header-topics-text {
    @apply text-sky-600 ml-2
  }

  .pk-header-filter-button-closed {
    @apply flex bg-sky-200 cursor-pointer items-center w-24 mr-4 my-2
    px-4 py-2 rounded-md
  }

  .pk-header-filter-button-open {
    @apply flex bg-sky-600 cursor-pointer items-center w-24 mr-4 my-2
    px-4 py-2 rounded-md
  }

  .pk-header-filter-text-open {
    @apply ml-2 text-white
  }

  .pk-header-filter-text-closed {
    @apply ml-2 text-sky-600
  }

  .pk-main {
    @apply flex h-full
  }

  .pk-content {
    @apply flex flex-col grow shrink justify-between h-full
  }

  .pk-message-area {
    @apply w-4/5 mx-auto
  }

  .pk-message {
    @apply flex border-2 border-sky-200 border-solid rounded-md mb-5 p-3 items-center
  }

  .pk-message-icon {
    @apply bg-sky-200 p-3 rounded-md mr-3
  }

  .pk-message-contents {
    @apply flex-col
  }

  .pk-message-title {
    @apply text-sm text-slate-400
  }

  .pk-messsage-text {
    @apply text-sky-600 rounded-md grow
  }

  .pk-filter-params {
    @apply bg-white text-blue-900 relative border-sky-200;
    max-height: 666px;
    border-bottom-width: 1px;
    box-shadow: -2px 2px rgba(0, 0, 0, 0.1);
  }

  .pk-filter-head {
    @apply flex text-blue-900 text-lg justify-between my-4 w-4/5 mx-auto
  }

  .pk-filter-options {
    @apply flex flex-col text-blue-900
    my-4 w-4/5 mx-auto
  }

  .pk-filter-checkbox {
    border-bottom-color: #b6bcc2;
    border-bottom-width: 1px;
    color: #1e3a8a;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .pk-filter-checkbox-title {
    margin-bottom: 10px;
  }

  .pk-filter-checkbox-item {
    margin-bottom: 2px;
    margin-top: 2px;
  }

  .pk-filter-checkbox-item-text {
    margin-left: 6px;
  }

  .pk-slider-area {
    border-bottom: 1px solid #b6bcc2;
    background-color: white;
    color: #1e3a8a;
    width: 100%;
    max-width: 320px;
    padding: 10px 0px 20px;
  }

  .pk-slider-area-title {
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .pk-slider-area-values {
    background-color: #daebfe;
    border-radius: 10px;
    font-weight: 500;
    text-align: center;
    width: 160px;
    padding: 10px;
    margin: 0px auto;
  }

  .pk-slider {
    width: 100%;
    height: 6px;
    background-color: #cdd9ed;
    margin-top: 20px;
  }

  .pk-slider .thumb {
    width: 27px;
    height: 27px;
    cursor: grab;
    background-color: white;
    border-radius: 50%;
    /* border: 2px solid #275efe; */
    top: -11px;
  }

  .pk-slider .thumb-0 {
    background-color: #2774ae;
  }

  .pk-slider .thumb-1 {
    background-color: #003b5c;
  }

  .pk-slider .track {
    height: 6px;
  }

  .pk-slider .track-1 {
    background: linear-gradient(0.25turn, #2774ae, #003b5c);
  }

  .pk-color-area-title {
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .pk-color-select {
    display: block;
    border: 2px solid #bae6fd;
    border-radius: 10px;
    background-color: #e0f2fe;
    color: #1e3a8a;
    width: 100%;
    padding: 10px;
    margin: 0px auto;
  }

  .pk-apply-area {
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .pk-filters-apply {
    border-radius: 10px;
    background-color: #2774ae;
    color: white;
    width: 100%;
    padding: 10px;
  }

  .pk-filters-apply :disabled {
    background-color: #e0e0e0;
    color: #808080;
}


  /* ChatMessage Mobile */
  @media screen and (max-width: 768px) {
    .message__mobile {
      font-family: 'Mulish';
      font-size: 12px;
      font-weight: 400;
      line-height: 15.06px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #434343;
    }

    .message__wrapper__mobile {
      @apply shadow-xl mt-2 p-4 rounded-lg;
      width: 100%;
      background-color: white;
    }

    .message__markdown__mobile {
      @apply prose text-left;
    }

    .token__usage__mobile {
      @apply text-left mt-2 text-gray-300;
    }

    .message__actions__mobile {
      @apply flex justify-between mt-2 items-center;
    }

    .message__avatar__mobile {
      @apply w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center;
    }

    .message__avatar__image__mobile {
      @apply w-4 h-4;
    }

    .message__action__button__mobile {
      @apply btn btn-ghost btn-xs rounded-full;
      background-color: transparent;
    }

    .message__action__icon__mobile {
      @apply w-4 h-4;
      color: #434343;
    }
  }

  /* Model Params */
  .modelparams {
    min-width: 345px;
    max-width: 345px;
  }

  .modelparams__toggle{
    left: -30px;
    border-right: 22px solid #00325D;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    height: 68px;
    width: 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .modelparams__img {
    position: absolute;
    min-width: 23px;
    top: 14px;
    left: 2px;
  }

  .range-config {
    --range-shdw: 208, 100%, 36%;
  }

  /* Config Container */

  .mobile-drawer {
    @apply fixed top-0 right-0 h-full z-50 transition-transform duration-300 ease-in-out border-l-8;
  }


  /* Signin Page */
  .signin {
    @apply flex flex-col justify-center items-center h-screen w-screen overflow-hidden bg-dark-grey;
  }

  .signin__container {
    @apply border-4 rounded-2xl h-72 w-72 p-5 flex flex-col justify-center items-center;
  }

  .signin__btn {
    @apply h-12 px-4 rounded-xl bg-slate-100 hover:bg-slate-300 border
     border-light-grey focus:ring-offset-indigo-200 text-black transition
    ease-in-out duration-300 text-center text-base font-semibold shadow-md
    focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .signin__tos {
    @apply text-white text-center font-bold p-2;
  }
}

::-webkit-scrollbar {
  @apply bg-transparent w-1;
}

::-webkit-scrollbar-thumb {
  @apply bg-light-grey dark:bg-dark-grey rounded-xl;
}
